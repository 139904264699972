/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineAsyncComponent } from 'vue'

import Alert from '~/src/Domain/DataRequest/Fixtures/Content/Alert/Alert'
import Checklist from '~/src/Domain/DataRequest/Fixtures/Content/Checklist/Checklist'
import CodePreview from '~/src/Domain/DataRequest/Fixtures/Content/CodePreview/CodePreview'
import type ContentInterface from '~/src/Domain/DataRequest/Fixtures/Content/ContentInterface'
import FileUploader from '~/src/Domain/DataRequest/Fixtures/Content/FileUploader/FileUploader'
import Image from '~/src/Domain/DataRequest/Fixtures/Content/Image/Image'
import Header from '~/src/Domain/DataRequest/Fixtures/Content/Paragraph/Header'
import Paragraph from '~/src/Domain/DataRequest/Fixtures/Content/Paragraph/Paragraph'
import Section from '~/src/Domain/DataRequest/Fixtures/Content/Section/Section'

export default function useContentComponent(content: ContentInterface): ReturnType<typeof defineAsyncComponent> {
  switch (true) {
    case content instanceof Alert:
      return defineAsyncComponent(
        async () => import('~/src/UserInterface/DataRequest/components/Content/Alert/Alert.vue'),
      )
    case content instanceof Checklist:
      return defineAsyncComponent(
        async () => import('~/src/UserInterface/DataRequest/components/Content/Checklist/Checklist.vue'),
      )
    case content instanceof CodePreview:
      return defineAsyncComponent(
        async () => import('~/src/UserInterface/DataRequest/components/Content/CodePreview/CodePreview.vue'),
      )
    case content instanceof FileUploader:
      return defineAsyncComponent(
        async () => import('~/src/UserInterface/DataRequest/components/Content/FileUploader/FileUploader.vue'),
      )
    case content instanceof Image:
      return defineAsyncComponent(
        async () => import('~/src/UserInterface/DataRequest/components/Content/Image/Image.vue'),
      )
    case content instanceof Header:
      return defineAsyncComponent(
        async () => import('~/src/UserInterface/DataRequest/components/Content/Paragraph/Header.vue'),
      )
    case content instanceof Paragraph:
      return defineAsyncComponent(
        async () => import('~/src/UserInterface/DataRequest/components/Content/Paragraph/Paragraph.vue'),
      )
    case content instanceof Section:
      return defineAsyncComponent(
        async () => import('~/src/UserInterface/DataRequest/components/Content/Section/Section.vue'),
      )
    default:
      throw new Error(`No component defined for content type ${content.constructor.name}`)
  }
}
