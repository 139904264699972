<script lang="ts" setup>
import { computed } from 'vue'
import type ContentInterface from '~/src/Domain/DataRequest/Fixtures/Content/ContentInterface'
import type Slug from '~/src/Domain/Shared/Identifier/Slug'
import useContentComponent from '~/src/UserInterface/DataRequest/composables/useContentComponent'
import type DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

const props = defineProps<{
  content: ContentInterface
  currentStepSlug: Slug
  dataRequest: DataRequestProjection
}>()
const component = computed(() => useContentComponent(props.content))
</script>

<template>
  <component
    :is="component"
    :content="content"
    :current-step-slug="currentStepSlug"
    :data-request="dataRequest"
  />
</template>
